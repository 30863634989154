import { Component, NgModule, OnInit } from '@angular/core';

import { Shirt } from '../shared/model/shirt.model';
import { ShirtService } from '../shared/model/shirt.service';

@Component({
  selector: 'app-shirt-sets',
  templateUrl: './shirt-sets.component.svg',
  styleUrls: ['./shirt-sets.component.css']
})

@NgModule({
  providers: [ShirtService]
})
export class ShirtSetsComponent implements OnInit {
  currentShirt: Shirt;
  shirts: Shirt[];
  
  constructor(private shirtService: ShirtService) { 
  }

  ngOnInit() {
    this.currentShirt = new Shirt( null, null, null, null, null);
    this.shirtService.getShirts()
      .subscribe(shirt => this.shirts = shirt);  
  }

  setCurrentShirt(event: MouseEvent) {
    var dotId = (<SVGAElement> event.currentTarget).id;  

    this.shirts.forEach(shirt => { 
      if (shirt.dot === dotId ) {
        this.shirtService.Stream.next(shirt);
        this.currentShirt = shirt;
        return;
      }
    });
  }
}
