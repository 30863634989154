import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShirtSetsComponent } from './shirt-sets/shirt-sets.component';
import { ShirtDetailsComponent } from './shirt-details/shirt-details.component';
import { ShirtService } from './shared/model/shirt.service';

@NgModule({
  declarations: [
    AppComponent,
    ShirtSetsComponent,
    ShirtDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [ShirtService],
  bootstrap: [AppComponent]
})
export class AppModule { }
