import { Component, OnInit } from '@angular/core';

import { Shirt } from './shared/model/shirt.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'shirts-app';
  currentShirt: Shirt;
  
  shirts: Shirt[];

  constructor() {
  }

  ngOnInit() {
  }

}
