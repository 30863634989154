import { Component, OnInit } from '@angular/core';

// import { Orientation } from '../shared/model/orientation.model';
import { Shirt } from '../shared/model/shirt.model';
import { ShirtService } from '../shared/model/shirt.service';

@Component({
  selector: 'app-shirt-details',
  templateUrl: './shirt-details.component.html',
  styleUrls: ['./shirt-details.component.css']
})

export class ShirtDetailsComponent implements OnInit {
  currentShirt: Shirt;

  constructor(private shirtService:ShirtService) {  
  }

  ngOnInit() {
    this.currentShirt = new Shirt(null, null, null, null, null);
    this.shirtService.Stream
      .subscribe((shirt:Shirt) => this.processCurrentShirt(shirt))
  }

  processCurrentShirt(shirt:Shirt) {
    console.log(`Current shirt detail: ${shirt.name}`);
    this.currentShirt = shirt;
  }
}
