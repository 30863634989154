import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { Shirt } from './shirt.model';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class ShirtService {
    Stream: Subject<Shirt>;

    constructor(private http: HttpClient) {
        this.Stream = new Subject();
    }

    getShirts(): Observable<Shirt[]> {
        return this.http
            .get<Shirt[]>('../assets/data/shirts.json')
            .pipe(tap
                (result => console.log(`Got shirts.`))
            );
    }
}