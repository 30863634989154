import { Image } from './image.model';

export class Shirt{
    constructor(
        public id: string,
        public name: string,
        public dot: string,
        public image: Image,
        public shop_link: string) {
    }
}